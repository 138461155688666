import React from "react";
import { Select } from "../../../helpers";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { addressByTypeAndParentOptions } from "../../../../selectors/addresses";
import { FilterMainComponentProps } from "../Filter";

const BillingAddressSelect = ({
  values,
  onChange,
  placeholder = "All",
  dependsOnValues,
}: FilterMainComponentProps) => {
  // @ts-ignore
  const [client_id] = dependsOnValues;
  const billingAddressOptions = createOptions(
    useSelector((s) =>
      // @ts-ignore
      addressByTypeAndParentOptions(s, "BILLING", "CLIENT", client_id),
    ),
    "value",
    "label",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={billingAddressOptions}
      placeholder={placeholder}
    />
  );
};

export default BillingAddressSelect;
